import React from 'react'
import Layout from "../../../../components/layout";
import HeaderPaginas from '../../../../components/headerPaginas'
import Seo from '../../../../components/SEO/SEO';
import { GrDatabase, GrNotes, GrUserAdmin, GrInfo, GrWorkshop, GrCurrency, GrSystem, GrCaretNext, GrCalculator } from "react-icons/gr"
import { IconContext } from 'react-icons'
import CtaServicios from '../../../../components/ctaServicios'


export default function ProteccionDato(props) {

    return (
        <Layout>
            <Seo pathname={props.location.pathname} />
            <div className="cabecera-servicio">
                <HeaderPaginas titulo="Protección del dato" imagen="/img/cabeceras/proteccion-dato-bullhost.jpg" />
                <div className="intro-servicio">
                    <p>Proteger tus datos mucho más allá de tu empresa y su infraestructura tecnológica. Los datos viajan, que lleguen sólo a donde tienen que llegar también es importante y ahora podemos controlarlo.</p>
                </div>
            </div>
            <div className="cuerpo-servicio">
                <section className="">
                    <div className="limitador contenido__servicio">
                        <h2 className="titulo_contenido">Evitamos fugas de información. Protegemos tus datos.</h2>
                        <div>
                            <p>En Bullhost trabajamos nuestro servicio de protección del dato de la mano y con la tecnología de uno de los mas relevantes fabricantes específicos: <a href="https://www.safetica.com/" target="_blank" rel="noreferrer noopener">Safetica</a>, con dos objetivos claros:</p>
                            <ul>
                                <li>Proteger tus datos.</li>
                                <li>Controlar sus flujos de movimientos.</li>
                            </ul>
                            <br/>
                            <p>Para que exista una fuga de información no debes sufrir un ataque exterior grave, es suficiente con que en tu empresa haya una persona insatisfecha, alguien que no cumpla con los procedimientos de seguridad o cometa un error tus datos pueden verse expuestos y, con ello, las pérdidas.</p>
                        </div>
                    </div>
                </section>
                <section className="bloque-oscuro">
                    <div className="bloque-tips limitador contenido__servicio bloque-tips__bloque-oscuro">
                    <h2>¿Cuáles son los datos más vitales de las empresas?</h2>
                    <IconContext.Provider value={{ fill: "#fff", stroke: "#fff", color: "#fff", size: "40%", className: "serv-icono-svg" }}>
                    <ul>
                        <li>
                            <div className="tip-icono"><GrUserAdmin /></div>
                            <p>
                            Datos personales
                            </p>
                        </li>
                        <li>
                            <div className="tip-icono"><GrNotes/></div>
                            <p>
                            Contratos
                            </p>
                        </li>
                        <li>
                            <div className="tip-icono"><GrCurrency/></div>
                            <p>
                            Datos de ventas
                            </p>
                        </li>
                        <li>
                            <div className="tip-icono"><GrSystem/></div>
                            <p>
                            Diseños y prototipos
                            </p>
                        </li>
                        <li>
                            <div className="tip-icono"><GrInfo/></div>
                            <p>
                            Conocimiento
                            </p>
                        </li>
                        <li>
                            <div className="tip-icono"><GrDatabase/></div>
                            <p>
                            Bases de datos
                            </p>
                        </li>
                        <li>
                            <div className="tip-icono"><GrCalculator/></div>
                            <p>
                            Facturación
                            </p>
                        </li>
                        <li>
                            <div className="tip-icono"><GrWorkshop/></div>
                            <p>
                            Planes estratégicos
                            </p>
                        </li>
                    </ul>
                    </IconContext.Provider>
                    </div>
                </section>
                <section className="limitador contenido__servicio">
                    <p className="parrafo-destacado">Que las personas estén informadas es necesario, pero no suficiente. Necesitas un software que vaya más allá.</p>
                    <p>Para ello nuestro equipo de ciberseguridad establece con tu equipo directivo un proceso, lógico y necesario:</p>
                    <ul className="lista-flujo">
                    <IconContext.Provider value={{ fill: "#1f1f1f", stroke: "#1f1f1f", color: "#1f1f1f", size: "6rem" }}>
                        <li><h4>Detectar cuáles son las amenazas de los datos en tu empresa.</h4></li>
                        <li className="flecha-flujo"><GrCaretNext/></li>
                        <li><h4>Trazar un plan de protección y formar y educar a las personas.</h4></li>
                        <li className="flecha-flujo"><GrCaretNext/></li>
                        <li><h4>Nuestro DLP para evitar que los datos relevantes salgan de tu empresa sin control.</h4></li>
                    </IconContext.Provider>
                    </ul>
                    <p>De esta manera, podremos:</p>
                    <ul>
                        <li>Tener el control: quién puede acceder a los datos, desde que ubicaciones o dispositivos acceden a los mismos.</li>
                        <li>Definir los niveles de acceso de cada usuario mediante tecnología Safetica.</li>
                        <li>Supervisar cada acceso de cada usuario con el objetivo identificar conductas de riesgo o peligros para la seguridad de los datos.</li>
                        <li>Bloquear el acceso a los usuarios y destruir digitalmente un documento si ello fuera necesario.</li>
                    </ul>
                </section>
            </div>
            <CtaServicios/>
        </Layout>
    )
}